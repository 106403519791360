<template>


  <div class="home">
    <!-- 슬라이드 카드 섹션 -->
    <div id="myCarousel" class="carousel slide mb-6" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true"
          aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item  active"
          :style="{ backgroundImage: 'url(' + require('@/assets/images/main0.png') + ')', backgroundSize: 'cover', backgroundPosition: 'center' }"
          style="background-size: 1900px 800px; ">
          <!-- <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="rgb(254, 254, 243, 0.75)" />
          </svg> -->
          <div class="container">
            <div class="carousel-caption text-start">
              <!-- <h2 style="margin-bottom: 2rem; font-weight: 600;">스마트 체온계</h2>
              <p>가정용 의료기기 닥터인홈을 통해 <br>병원 진료 후 체계적인 관리를 해보세요.</p> -->
              <!-- <p>
                <a class="btn btn-lg btn-primary" href="/qna" v-if="$store.state.language === 'ko'">더 자세히 알아보기</a>
                <a class="btn btn-lg btn-primary" href="/qna" v-if="$store.state.language === 'en'">Learn more</a>
              </p> -->
            </div>
          </div>
        </div>
        <div class="carousel-item"
          :style="{ backgroundImage: 'url(' + require('@/assets/images/main2.png') + ')', backgroundSize: 'cover', backgroundPosition: 'center' }"
          style="background-size: 1900px 800px; ">
          <!-- <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="h                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ttp://www.w3.org/2000/svg"
            aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--bs-secondary-color)" />
          </svg> -->
          <div class="container">
            <div class="carousel-caption">
              <h2 v-if="$store.state.language === 'ko'" style="margin-bottom: 2rem; font-weight: 600;">스마트 체온계</h2>
              <h2 v-if="$store.state.language === 'en'" style="margin-bottom: 2rem; font-weight: 600;">Tonsillitis
                camera</h2>
              <p v-if="$store.state.language === 'ko'">가정용 의료기기 닥터인홈을 통해 <br>병원 진료 후 체계적인 관리를 해보세요.</p>
              <p v-if="$store.state.language === 'en'">DoctorInHome's second medical device <br>You can take care of
                your neck with a tonsillitis camera.</p>
              <!-- <p>
                <a class="btn btn-lg btn-primary" href="/about#services" v-if="$store.state.language === 'ko'">중이염 증상
                  확인하러 가기</a>
                <a class="btn btn-lg btn-primary" href="/about#services" v-if="$store.state.language === 'en'">Check for
                  otitis media symptoms</a>
              </p> -->
            </div>
          </div>
        </div>
        <div class="carousel-item"
          :style="{ backgroundImage: 'url(' + require('@/assets/images/main3.png') + ')', backgroundSize: 'cover', backgroundPosition: 'center' }"
          style="background-size: 1900px 800px; ">
          <!-- <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="h                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                ttp://www.w3.org/2000/svg"
            aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--bs-secondary-color)" />
          </svg> -->
          <div class="container">
            <div class="carousel-caption">
              <h2 v-if="$store.state.language === 'ko'" style="margin-bottom: 2rem; font-weight: 600;">편도염 카메라</h2>
              <h2 v-if="$store.state.language === 'en'" style="margin-bottom: 2rem; font-weight: 600;">Tonsillitis
                camera</h2>
              <p v-if="$store.state.language === 'ko'">닥터인홈의 두 번째 의료기기 <br>편도염 카메라로 목까지 관리할 수 있습니다. </p>
              <p v-if="$store.state.language === 'en'">DoctorInHome's second medical device <br>You can take care of
                your neck with a tonsillitis camera.</p>
              <!-- <p>
                <a class="btn btn-lg btn-primary" href="/about#services" v-if="$store.state.language === 'ko'">중이염 증상
                  확인하러 가기</a>
                <a class="btn btn-lg btn-primary" href="/about#services" v-if="$store.state.language === 'en'">Check for
                  otitis media symptoms</a>
              </p> -->
            </div>
          </div>
        </div>
        <div class="carousel-item"
          :style="{ backgroundImage: 'url(' + require('@/assets/images/main4.png') + ')', backgroundSize: 'cover', backgroundPosition: 'center' }"
          style="background-size: 1900px 800px; ">
          <!-- <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--bs-secondary-color)" />
          </svg> -->
          <div class="container">
            <div class="carousel-caption">
              <h2 v-if="$store.state.language === 'ko'" style="margin-bottom: 2rem; font-weight: 600;">닥터인홈</h2>
              <h2 v-if="$store.state.language === 'en'" style="margin-bottom: 2rem; font-weight: 600;">DoctorInHome</h2>
              <p v-if="$store.state.language === 'ko'">꾸준히 기록한 아이 기록이 보기 편하게 정리됩니다. <br>아이의 체온/처방 기록을 한눈에 확인해보세요.</p>
              <p v-if="$store.state.language === 'en'">Steadily recorded child records are organized in an easy way to
                see.<br>Check your child's temperature/prescription record at a glance.</p>
              <!-- <p>
                <a class="btn btn-lg btn-primary" href="/notice/noticeDetail/2"
                  v-if="$store.state.language === 'ko'">위험성 알아보기</a>
                <a class="btn btn-lg btn-primary" href="/notice/noticeDetail/2"
                  v-if="$store.state.language === 'en'">Understanding the Risk</a>
              </p> -->
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>


    <!-- Marketing messaging and featurettes
================================================== -->
    <!-- Wrap the rest of the page in another container to center all the content. -->

    <div class="container marketing" style="padding-top: 5rem;">




      <!-- START THE FEATURETTES -->

      <div class="row featurette" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-md-12 what-is">
          <h2 class="featurette-heading fw-normal lh-1" v-if="$store.state.language === 'ko'"
            style="margin-top: 10 !important; font-weight: 600 !important; font-size: 30px;">우리 아이 건강은 매일 관리해야 합니다.</h2>
          <h2 class="featurette-heading fw-normal lh-1" v-if="$store.state.language === 'en'"
            style="margin-top: 10 !important; font-weight: 600 !important; font-size: 30px;">You have to take care of
            your child's health every day.</h2>
        </div>
        <div class="row" style="justify-content: center;">
          <div class="col-lg-4">
            <img src="@/assets/images/intro-21.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="430"
              height="330" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
            <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg> -->
            <h4 class="fw-normal2" v-if="$store.state.language === 'ko'">중이염</h4>
            <h4 class="fw-normal2" v-if="$store.state.language === 'en'">Otitis media</h4>
            <p v-if="$store.state.language === 'ko'">청력 저하 / 인지 능력 저하 / 언어 발달 저하</p>
            <p v-if="$store.state.language === 'en'">Hearing loss / Cognitive decline / Language development decline</p>
            <!-- <p>
            <a class="btn btn-secondary" href="/Product/Detail2" v-if="$store.state.language === 'ko'">상세 보기 &raquo;</a>
            <a class="btn btn-secondary" href="/Product/Detail2" v-if="$store.state.language === 'en'">View Details
              &raquo;</a>
          </p> -->

          </div>
          <div class="col-lg-4">
            <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg> -->
            <img src="@/assets/images/intro-22.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="430"
              height="330" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
            <h4 class="fw-normal2" v-if="$store.state.language === 'ko'">편도염</h4>
            <h4 class="fw-normal2" v-if="$store.state.language === 'en'">Tonsillitis</h4>
            <p v-if="$store.state.language === 'ko'">생김새 변형 / 코골이 / 성장 저하 / 중이염 위험 증가</p>
            <p v-if="$store.state.language === 'en'">Shape deformation / Snoring / Declining growth / Increasing risk of
              otitis media</p>
            <!-- <p>
            <a class="btn btn-secondary" href="/App" v-if="$store.state.language === 'ko'">상세 보기 &raquo;</a>
            <a class="btn btn-secondary" href="/Product/Detail" v-if="$store.state.language === 'en'">View Details
              &raquo;</a>
          </p> -->
          </div>
        </div>
        <div class="col-md-12 what-is">
          <p class="lead" v-if="$store.state.language === 'ko'">중이염이나 편도염의 치료 시기를 놓칠 경우 <span
              style="font-weight: 600;">부작용이나 합병증</span>이 유발될 수 있습니다.
            <br>지속적인 관찰과 빠른 치료는 우리 아이의 건강을 지키는 길입니다.
          </p>
          <p class="lead" v-if="$store.state.language === 'en'">Missing treatment timing for otitis media or tonsillitis
            may cause <span style="font-weight: 600;">side effects or complications</span>. <br>Continuous observation
            and quick treatment are the way to protect our child's health. </p>
        </div>
      </div>

      <hr class="featurette-divider">

      <div class="row featurette" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-md-7">
          <h2 class="featurette-heading fw-normal lh-1" v-if="$store.state.language === 'ko'"
            style="font-weight: 600 !important; margin-top: 13rem; font-size: 40px;">내과의 절반도 안되는 <span
              style="color: #FE6F0F;">소아과</span> 병원</h2>
          <h2 class="featurette-heading fw-normal lh-1" v-if="$store.state.language === 'en'"
            style="font-weight: 600 !important; margin-top: 13rem; font-size: 40px;">Of the internal medicine, <span style="color: #FE6F0F;">pediatric</span> hospitals are less than half</h2>
          <!-- <p class="lead" v-if="$store.state.language === 'ko'">어렵게 방문한 병원, 진단 후 아이 케어 <br>집에서 내 손으로 5분만에 끝! 닥터인홈을 경험해보세요.</p>
          <p class="lead" v-if="$store.state.language === 'en'">Hospital visited with difficulty, child care after
            diagnosis <br>At home, with my hands, it's over in 5 minutes! Experience Doctor in Home.</p> -->
        </div>
        <div class="col-md-5">
          <!-- <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-bg)"/><text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">500x500</text></svg> -->
          <!-- <img src="@/assets/images/item-1.jpg" alt="스마트체온계 제품사진" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"> -->
          <div class="card-hos">
            <div class="card-body-hos pb-0">
              <h5 class="card-title-hos" v-if="$store.state.language === 'ko'">전국 병원 개수 <span>| 2024.01 기준 / 건강보험심사평가원
                  출처</span></h5>
              <h5 class="card-title-hos" v-if="$store.state.language === 'en'">The number of hospitals nationwide
                <span>| As of 2024.01 / Sources of HIRA</span>
              </h5>
              <div id="trafficChart" style="min-height: 400px;" class="echart"></div>
            </div>
          </div>
        </div>
      </div>

      <hr class="featurette-divider">

      <div class="row featurette" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-md-7 order-md-2">
          <h2 class="featurette-heading fw-normal lh-1" v-if="$store.state.language === 'ko'"
            style="font-weight: 600 !important; margin-top: 13rem; font-size: 40px;">계속해서 줄어드는 <span
              style="color: #FE6F0F;">소아과 전공의 <br></span></h2>
          <h2 class="featurette-heading fw-normal lh-1" v-if="$store.state.language === 'en'"
            style="font-weight: 600 !important; margin-top: 13rem; font-size: 40px;">Pediatric residents <span
              style="color: #FE6F0F;">continue to decline <br></span></h2>
          <!-- <p class="lead" v-if="$store.state.language === 'ko'">성인에게 가벼운 열감기도 치명적인 우리 아이 <br>병원 방문이 어려워도 집에서 지속적인 케어가
            필요합니다.</p>
          <p class="lead" v-if="$store.state.language === 'en'">For my child, even a mild fever is fatal to an adult.
            <br>Even if visiting the hospital is difficult, ongoing care is needed at home.
          </p> -->
        </div>
        <div class="col-md-5 order-md-1" style="text-align: center;">
          <!-- <img src="@/assets/images/item-2.png" alt="스마트체온계 제품사진" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false" style="max-width: 250px; height: auto;"> -->
          <div id="reportsChart">
            <p style="text-align: left; font-size: 14px;" v-if="$store.state.language === 'ko'">출처 : 대한소아청소년과학회</p>
            <p style="text-align: left; font-size: 14px;" v-if="$store.state.language === 'en'">Source from The Korean
              Pediatric Society</p>
            <p style="text-align: right; font-size: 15px;" v-if="$store.state.language === 'ko'">단위 : %</p>
            <p style="text-align: right; font-size: 15px;" v-if="$store.state.language === 'en'">unit : %</p>
          </div>
        </div>
      </div>

      <hr class="featurette-divider">

      <div class="row featurette" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-md-12 what-is">
          <h2 class="featurette-heading fw-normal lh-1" v-if="$store.state.language === 'ko'"
            style="margin-top: 10 !important; font-weight: 600 !important; "> <span style="color: #FE6F0F;">스마트</span>
            하게 <span style="color: #FE6F0F;">닥터인홈</span> 하세요.</h2>
          <h2 class="featurette-heading fw-normal lh-1" v-if="$store.state.language === 'en'"
            style="margin-top: 10 !important; font-weight: 600 !important; "> Be smart,<span style="color: #FE6F0F;">
              Doctor in Home.</span></h2>
        </div>
        <div class="col-md-12 what-is">
          <img v-if="$store.state.language === 'ko'" src="@/assets/images/item-3.png" alt="스마트체온계 제품사진"
            class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="700" height="500"
            aria-label="Placeholder: 700x500" preserveAspectRatio="xMidYMid slice" focusable="false">
          <img v-if="$store.state.language === 'en'" src="@/assets/images/item-3eng.png" alt="스마트체온계 제품사진"
            class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="700" height="500"
            aria-label="Placeholder: 700x500" preserveAspectRatio="xMidYMid slice" focusable="false">
        </div>
        <div class="col-md-12 what-is">
          <p class="lead" v-if="$store.state.language === 'ko'">내시경 카메라와 APP 연동으로 사진 이동이 간편합니다. <br>우리 아이 <span
              style="font-weight: 600;">중이염</span>이나 <span style="font-weight: 600;">편도염</span>의 진행 단계를 어디서나 확인해보세요.</p>
          <p class="lead" v-if="$store.state.language === 'en'">It is easy to move photos with endoscopic cameras and
            APP interworking. <br>Check out the progression stages of our child's <span style="font-weight: 600;">otitis
              media</span> or <span style="font-weight: 600;">tonsillitis</span> everywhere. </p>
        </div>
      </div>

      <!-- <hr class="featurette-divider">

      <div class="row featurette">
        <div class="col-md-7 order-md-2">
          <h2 class="featurette-heading fw-normal lh-1" v-if="$store.state.language === 'ko'">처방약과 복약 기록으로 정확한 진단이
            가능합니다.</h2>
          <h2 class="featurette-heading fw-normal lh-1" v-if="$store.state.language === 'en'">An accurate diagnosis is
            possible with prescribed medications and medication records.</h2>
          <p class="lead" v-if="$store.state.language === 'ko'">항생제 성분은 별도로 표기되어 약 조절이 가능합니다. </p>
          <p class="lead" v-if="$store.state.language === 'en'">Antibiotic ingredients are listed separately so
            medication can be adjusted. </p>
        </div>
        <div class="col-md-5 order-md-1">
          <img src="@/assets/images/item-4.png" alt="스마트체온계 제품사진"
            class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500"
            aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false">
        </div>
      </div> -->

      <hr class="featurette-divider">

      <!-- /END THE FEATURETTES -->

      <!-- Three columns of text below the carousel -->
      <div class="row" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-lg-4">
          <img src="@/assets/images/intro-1.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="360" height="260"
            aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
          <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg> -->
          <h4 class="fw-normal2" v-if="$store.state.language === 'ko'">가족과 함께</h4>
          <h4 class="fw-normal2" v-if="$store.state.language === 'en'">With my family</h4>
          <p v-if="$store.state.language === 'ko'">가족 구성원이라면 누구나 기록 가능합니다. <br>우리 아이 증상을 기록하고 실시간으로 확인하세요.</p>
          <p v-if="$store.state.language === 'en'">Any family member can record it. <br>Record our child's symptoms and
            check them in real time.</p>
          <!-- <p>
            <a class="btn btn-secondary" href="/Product/Detail" v-if="$store.state.language === 'ko'">상세 보기 &raquo;</a>
            <a class="btn btn-secondary" href="/Product/Detail" v-if="$store.state.language === 'en'">View Details
              &raquo;</a>
          </p> -->

        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4">
          <img src="@/assets/images/intro-4.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="360" height="260"
            aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
          <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg> -->
          <h4 class="fw-normal2" v-if="$store.state.language === 'ko'">전문적인 케어</h4>
          <h4 class="fw-normal2" v-if="$store.state.language === 'en'">Professional care</h4>
          <p v-if="$store.state.language === 'ko'">내시경 카메라로 호전 여부를 확인할 수 있습니다. <br>꾸준한 관리로 단순치료 시기를 놓치지 마세요.</p>
          <p v-if="$store.state.language === 'en'">You can check whether the disease has improved with an endoscopic
            camera. <br>Don't miss the period of simple treatment with constant management.</p>
          <!-- <p>
            <a class="btn btn-secondary" href="/Product/Detail2" v-if="$store.state.language === 'ko'">상세 보기 &raquo;</a>
            <a class="btn btn-secondary" href="/Product/Detail2" v-if="$store.state.language === 'en'">View Details
              &raquo;</a>
          </p> -->

        </div>
        <div class="col-lg-4">
          <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg> -->
          <img src="@/assets/images/intro-2.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="360" height="260"
            aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
          <h4 class="fw-normal2" v-if="$store.state.language === 'ko'">건강 관리 기록</h4>
          <h4 class="fw-normal2" v-if="$store.state.language === 'en'">Health care records</h4>
          <p v-if="$store.state.language === 'ko'">날짜별/시간별 기록으로 건강 점검이 간편합니다. <br>복약 기록으로 우리 아이에게 맞는 약을 찾아주세요.</p>
          <p v-if="$store.state.language === 'en'">Health checks are easy with date/hour records. <br>Please find the
            right medicine for my child with the medication record.</p>
          <!-- <p>
            <a class="btn btn-secondary" href="/App" v-if="$store.state.language === 'ko'">상세 보기 &raquo;</a>
            <a class="btn btn-secondary" href="/Product/Detail" v-if="$store.state.language === 'en'">View Details
              &raquo;</a>
          </p> -->
        </div>
        <!-- <div class="col-lg-4">
      <img src="@/assets/images/intro-3.png" alt="스마트체온계 제품사진" class="bd-placeholder-img rounded-circle" width="140" height="140" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
      <h2 class="fw-normal" v-if="$store.state.language === 'ko'">우리아이 케어</h2>
      <h2 class="fw-normal" v-if="$store.state.language === 'en'">care for my child</h2>
      <p>
        <a class="btn btn-secondary" href="/searchPill" v-if="$store.state.language === 'ko'">상세 보기 &raquo;</a>
        <a class="btn btn-secondary" href="/Product/Detail" v-if="$store.state.language === 'en'">View Details &raquo;</a>
      </p>
    </div> -->
      </div><!-- /.row -->

      <hr class="featurette-divider">

      <!-- 팝업창 / 메인 화면에만 등장 -->
      <div v-if="modalCheck" class="modal-wrap">
        <div class="modal-overlay" id="modalOverlay"></div>
        <div class="modal-container">
          <img :src="currentImage" alt="팝업창" class="modal-image">
          <div class="modal-btn">
            <button @click="closeModal">Close</button>
            <button @click="closeTodayPopup">Close for today</button>
          </div>
        </div>
      </div>

 

    </div><!-- /.container -->


  </div>
</template>
<script>
// import axios from 'axios';
// import {reactive} from 'vue';
import * as echarts from 'echarts';
import ApexCharts from 'apexcharts';
import { onMounted } from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'Home',
  setup() {
    onMounted(() => {
      AOS.init({
        // Optional settings
        duration: 450,
      });
    });
  },
  data() {
    return {
      modalCheck: false,
      todayPopupClosed: false,
      images: [
        require('@/assets/images/popup.jpg'),
        // require('@/assets/images/popup-6.png'),
        // require('@/assets/images/popup-3.png'),
        // require('@/assets/images/popup-5.png'),
      ],
      currentImageIndex: 0
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    }
  },
  created() {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'todayPopupClosed' && value === 'true') {
        this.todayPopupClosed = true;
      }
      if (name === 'backgroundTodayPopupClosed' && value === 'true') {
        this.backgroundTodayPopupClosed = true;
      }
    }
  },
  mounted() {
    // 화면 로드 시 차트 새로 고침
    this.drawChart();
    this.startSlideshow();

    // 화면이 로드될 때 모달을 열기 위해 modalCheck 값을 true로 설정
    if (!this.backgroundTodayPopupClosed) {
      this.backgroundModalCheck = true;
    }
    if (!this.todayPopupClosed) {
      this.modalCheck = true;
    }


    // 전국 소아과 전공의 지원율 차트
    new ApexCharts(document.querySelector("#reportsChart"), {
      series: [{
        name: '전국 소아과 전공의 지원율',
        data: [80, 74, 38, 27.5, 15.9],
      }],
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false
        },
      },
      markers: {
        size: 4
      },
      colors: ['#4154f1', '#2eca6a', '#ff771d'],
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.3,
          opacityTo: 0.4,
          stops: [0, 90, 100]
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 2
      },
      xaxis: {
        type: 'datetime',
        categories: ["2019", "2020", "2021", "2022", "2023"]
      },
      tooltip: {
        x: {
          format: 'yyyy'
        },
      }
    }).render();
  },
  methods: {
    closeModal() {
      this.modalCheck = false;
    },
    closeTodayPopup() {
      this.todayPopupClosed = true;
      this.modalCheck = false;

      // 현재 시간에서 24시간을 더한 만료 시간을 설정합니다.
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000);
      const expires = "expires=" + expirationDate.toUTCString();

      // 쿠키를 설정합니다.
      document.cookie = "todayPopupClosed=true;" + expires + ";path=/";
    },
    startSlideshow() {
      setInterval(() => {
        this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
      }, 1800); //1.8초마다 이미지 변경
    },
    changeLanguage(lang) {
      // 언어 변경
      this.$store.dispatch('changeLanguage', lang);
    },
    drawChart() {
      const chart = echarts.init(document.getElementById('trafficChart'));
      chart.setOption({
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [{
          name: '비교',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '18',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: [{
            value: 3275,
            name: '전국 소아과 수'
          },
          {
            value: 3042,
            name: '전국 이비인후과 수'
          },
          {
            value: 24744,
            name: '전국 약국 수'
          },
          {
            value: 7699,
            name: '전국 내과 수'
          }
          ]
        }]
      });
    }
  },
  components: {
  }

}


</script>
<style scoped>
@import 'aos/dist/aos.css';

.image-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-image {
  max-width: 100%;
  height: auto;
}

.what-is {
  text-align: center;
  padding-bottom: 4rem;
}

hr {
  border-top: none;
}

.fw-normal2 {
  padding-top: 2rem;
  font-weight: 600;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #44423b;
  border-radius: 50%; /* 둥근 모양으로 변경 */
}

.carousel-indicators button {
  background-color: #44423b;
  /* 기본 버튼 색상 */
}


.carousel-item {
  height: 50rem;
}

.carousel-caption {
  width: 45%;
  bottom: 31rem;
  color: #27272A;
  text-align: left;
}

/* 모달 스타일 */
.background-modal-container {
  position: absolute;
  top: 12%;
  left: 14%;
  width: 30%;
  max-width: 500px;
  max-height: 90%;
  background: inherit;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 999;
}

.modal-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 배경 불투명도 설정 */
  z-index: 998;
  /* 모달 위로 오게 설정 */
}

.modal-container {
  position: absolute;
  top: 12%;
  left: 37%;
  width: 30%;
  max-width: 500px;
  max-height: 90%;
  overflow-y: auto;
  background: inherit;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 1000;
  /* 오버레이 위로 오게 설정 */
}

.modal-image,
.background-modal-image {
  width: 100%;
  height: auto;
}

.popup-btn {
  background-color: #1f22ca;
  width: 100%;
}

.modal-btn {
  background-color: /*#1b31ad*/transparent;
  width: 100%;
}

.popup-btn button {
  border: none;
  border-radius: 8px;
  margin: 10px;
  background-color: #1f22ca;
  color: #fff;
}

.modal-btn button {
  border: none;
  border-radius: 8px;
  margin: 10px;
  background-color: /*#1b31ad*/transparent;
  color: #fff;
}

@media (max-width: 768px) {

  .background-modal-container,
  .modal-container {
    width: 80%;
    left: 10%;
  }
}

@media (max-width: 480px) {

  .background-modal-container,
  .modal-container {
    width: 90%;
    left: 5%;
  }
}

/* Card */
.card-hos {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header-hos,
.card-footer-hos {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}

.card-title-hos {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  font-family: "Poppins", sans-serif;
}

.card-title-hos span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.card-body-hos {
  padding: 0 20px 20px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}
</style>